import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import { Code2, ArrowUpCircle } from 'lucide-react';
import HomePage from './HomePage';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  const shareToTwitter = (location) => {
    const tweetText = encodeURIComponent("I'm excited about AI Code Guide! Join me in mastering AI-powered coding. Get early access and 25% off at launch: https://www.aicodeguide.com #AICodeGuide #CodingWithAI");
    window.open(`https://twitter.com/intent/tweet?text=${tweetText}`, '_blank');
    if (window.umami) window.umami.track('Share to X', { location });
  };

  const openProductHunt = (location) => {
    window.open('https://www.producthunt.com/posts/ai-code-guide', '_blank');
    if (window.umami) window.umami.track('Upvote on Product Hunt', { location });
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gradient-to-br from-[#2B2D42] to-[#1A1B2E] text-white">
        <header className="p-4 md:p-6 border-b border-white/10">
          <div className="container mx-auto">
            <div className="flex items-center justify-between">
              <Link to="/" className="flex items-center space-x-2">
                <Code2 className="h-8 w-8 text-[#007BFF]" />
                <span className="text-xl md:text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#007BFF] to-[#00BFFF]">AI Code Guide</span>
              </Link>
              <div className="hidden md:flex items-center space-x-4">
                <span className="hidden md:inline text-sm">Unlock AI Potential in Every Line of Code</span>
                <button 
                  onClick={() => shareToTwitter('header')}
                  className="hidden md:flex items-center space-x-2 bg-black text-white text-sm font-semibold py-2 px-4 rounded"
                >
                  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                  </svg>
                  <span>Share</span>
                </button>
                <button 
                  onClick={() => openProductHunt('header')}
                  className="hidden md:flex items-center space-x-2 bg-[#DA552F] text-white text-sm font-semibold py-2 px-4 rounded"
                >
                  <ArrowUpCircle className="h-4 w-4" />
                  <span>Upvote</span>
                </button>
              </div>
            </div>
          </div>
        </header>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>

        <footer className="bg-black/30 py-6 border-t border-white/10">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row md:justify-between md:items-start space-y-6 md:space-y-0 md:space-x-8">
              {/* Brand Section */}
              <div className="flex flex-col items-center md:items-start space-y-2 md:w-1/3">
                <div className="flex items-center space-x-2">
                  <Code2 className="h-5 w-5 text-[#007BFF]" />
                  <span className="text-xl font-bold">AI Code Guide</span>
                </div>
                <p className="text-gray-400 text-sm text-center md:text-left">
                  Empowering developers with AI-powered coding tools and resources.
                </p>
              </div>

              {/* Quick Links Section */}
              <div className="flex flex-col items-center md:items-start md:w-1/3">
                <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
                <div className="flex flex-col items-center md:items-start space-y-2">
                  <Link to="/privacy" className="text-gray-400 hover:text-[#007BFF] transition-colors duration-300">
                    Privacy Policy
                  </Link>
                  <a 
                    href="mailto:support@aicodeguide.com" 
                    className="text-gray-400 hover:text-[#007BFF] transition-colors duration-300"
                  >
                    Contact Support
                  </a>
                </div>
              </div>

              {/* Connect With Us Section */}
              <div className="flex flex-col items-center md:items-start md:w-1/3">
                <h3 className="text-lg font-semibold mb-2">Connect With Us</h3>
                <a 
                  href="https://x.com/circuitoftmrw" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-flex items-center space-x-2 bg-black/30 hover:bg-black text-gray-300 hover:text-white px-4 py-1.5 rounded-full transition-all duration-300 border border-gray-700 hover:border-blue-500"
                >
                  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                  </svg>
                  <span>@circuitoftmrw</span>
                </a>
              </div>
            </div>

            {/* Copyright Section - Full Width */}
            <div className="w-full text-center mt-6 pt-4 border-t border-gray-800">
              <p className="text-sm text-gray-400 mb-2">
                © 2024 AI Code Guide. All rights reserved.
              </p>
              <p className="text-xs text-gray-500">
                We respect your privacy and only collect essential information to improve your experience!
              </p>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
