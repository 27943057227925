import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8 text-white">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      
      <p className="mb-4">
        At AI Code Guide, we respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and safeguard your information.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">1. Information We Collect</h2>
      <p className="mb-4">We collect your name and email address when you join our waitlist, and anonymous usage data when you visit our website.</p>

      <h2 className="text-2xl font-bold mt-8 mb-4">2. How We Use Your Information</h2>
      <p className="mb-4">We use your information to manage your waitlist subscription, send updates about our product, and analyze website traffic to improve our services.</p>

      <h2 className="text-2xl font-bold mt-8 mb-4">3. Data Storage and Security</h2>
      <p className="mb-4">
        Your personal information is stored securely. We use industry-standard measures to protect your data against unauthorized access or disclosure.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">4. Third-Party Services</h2>
      <p className="mb-4">We use third-party services for analytics, data storage, and email communications. These services process your data according to their own privacy policies.</p>

      <h2 className="text-2xl font-bold mt-8 mb-4">5. Your Rights</h2>
      <p className="mb-4">
        You have the right to access your personal data and request corrections. After our product launch, you'll have the option to unsubscribe from our communications.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">6. Cookies and Tracking</h2>
      <p className="mb-4">
        We use cookie-free analytics that collect anonymous usage data to help us improve our website and services.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">7. Data Removal Requests</h2>
      <p className="mb-4">
        If you wish to request the removal of your personal data, please contact us at{' '}
        <a 
          href="mailto:dataremoval@aicodeguide.com" 
          className="text-[#007BFF] hover:underline"
        >
          dataremoval@aicodeguide.com
        </a>.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">8. Changes to This Policy</h2>
      <p className="mb-4">
        We may update this privacy policy from time to time. Any changes will be posted on this page.
      </p>

      <p className="mt-8 mb-4">Last updated: November 3, 2024</p>

      <Link to="/" className="text-[#007BFF] hover:underline">Back to Home</Link>
    </div>
  );
}

export default PrivacyPolicy;
